import { useNavigate, Link } from '@remix-run/react'


export default function Stepper({ activeStep, vehicle }) {
  let yearId, makeId, modelId, subModelId, engineId, vehicleId;
  if (vehicle) {
    ({ yearId, makeId, modelId, subModelId, engineId, vehicleId } = vehicle)
  }
  const navigate = useNavigate()

  let findVehiclePath;
  if (yearId && makeId && modelId && subModelId && engineId) {
    findVehiclePath = `/?year=${yearId}&make=${makeId}&model=${modelId}&submodel=${subModelId}&engine=${engineId}`
  } else {
    findVehiclePath = '/'
  }

  let findPartPath;
  if (vehicleId) {
    findPartPath = `/find-parts?vehicleID=${vehicleId}`
  } else {
    findPartPath = '/find-parts'
  }

	const steps = [
		{ 
      id: 'vehicle',
      label: 'Find your vehicle',
      path: findVehiclePath
    },
		{ 
      id: 'part',
      label: 'Find your parts',
      path: findPartPath
    },
		{ 
      id: 'checkout',
      label: 'Order your parts',
      path: '/order'
    },
	]

	return (
		<ul className="steps mx-auto w-full">
			{steps.map((step, idx) => (
          <li
            key={idx}
            onClick={() => { step.path && navigate(step.path) }}
            className={`cursor-pointer step ${idx <= activeStep ? 'step-success' : ''}`}
          >
            <p>{step.label}</p>
          </li>
			))}
		</ul>
	)
}
